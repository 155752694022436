<template>

  <v-card
    id="page"
    class="page"
  >
    <v-card-title class="text-uppercase font-weight-bold title-printed">
      Etat des encours &nbsp;<span class="v_printed"> du mois de {{ periodeDebt }}</span>
    </v-card-title>
    <v-divider></v-divider>
    <br>
    <div class="mx-auto">
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="col-md-6"
            v-if="!userData.length"
          >
            <v-sheet
              :color="`grey`"
              class="pa-3"
            >
              <v-skeleton-loader
                class="mx-auto"
                type="table"
              >
              </v-skeleton-loader>
            </v-sheet>
          </v-col>
          <v-col
            v-else
            cols="12"
            class="col-md-6"
          >
            <v-data-table
              :headers="headers"
              :items="userData"
              :items-per-page="-1"
              hide-default-footer
              item-key="date"
              dense
              class="elevation-1"
            >
              <template
                #header.options="{header}"
              >
                {{ header.text }}
              </template>

              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <div class="d-flex justify-center">
                      {{ formatDate(item.date) }}
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      {{ parseInt(item.activation).toLocaleString() }}
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <b>{{ withCurrency(item.amount) }}</b>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <b>{{ withCurrency(item.amountDiscount) }}</b>
                    </div>
                  </td>
                  <td class="hidden-print-only">
                    <div class="d-flex justify-center">
                      <v-btn
                        outlined
                        x-small
                        @click="details(item)"
                      >
                        <v-icon
                          x-small
                          class="me-1"
                        >
                          {{ icon.mdiEyeOutline }}
                        </v-icon>
                        détails
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:body.append>
                <tr class="">
                  <th>
                    <div class="d-flex justify-center border-0">
                      Total
                    </div>
                  </th>
                  <th class="bordure-gauche-0">
                    <div class="d-flex justify-center text-body-1 font-weight-bold bordure-gauche-0">
                      {{ sumField('activation').toLocaleString() }}
                    </div>
                  </th>
                  <th class="bordure-gauche-0">
                    <div class="d-flex justify-center text-body-1 bordure-gauche-0">
                      <b>{{ sumField('amount').toLocaleString() }} {{ currency }}</b>
                    </div>
                  </th>
                  <th class="bordure-gauche-0">
                    <div class="d-flex justify-center text-body-1 bordure-gauche-0">
                      <b>{{ sumField('amountDiscount').toLocaleString() }} {{ currency }}</b>
                    </div>
                  </th>
                  <th class="bordure-gauche-0 hidden-print-only">
                  </th>
                </tr>
              </template>
            </v-data-table>
            <div class="d-flex flex-row justify-end my-2">
              <v-btn
                class="printBtn w-full"
                color="primary"
                outlined
                @click="printDebtTable"
              >
                imprimer
              </v-btn>
            </div>
            <br>

            <p class="mb-0 footer text-sm">
              Document généré par SYGALIN SAS le {{ moment().format('LL à HH:m:s') }}
            </p>
          </v-col>
          <v-col
            cols="12"
            class="col-md-6 billTab"
            v-if='loadingDept'
          >
            <v-sheet
              :color="`grey`"
              class="pa-3"
            >
              <v-skeleton-loader
                class="mx-auto"
                type="article"
              >
              </v-skeleton-loader>
              <v-skeleton-loader
                class="d-flex justify-center"
                type="button"
              >
              </v-skeleton-loader>
            </v-sheet>
          </v-col>
          <v-col
            v-else-if="debt!==undefined"
            cols="12"
            class="col-md-6 billTab"
          >
            <v-card
              v-if="userData.length"
              elevation="1"
              class="mt-2"
              tile
            >
              <v-row>
                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                >
                  <v-card-text>
                    <b>Facture</b>
                    <v-spacer></v-spacer>
                    <span class="text-uppercase font-weight-bold">{{ litteralMonth(paramMonth) }} {{ paramYear }}</span>
                    <v-spacer></v-spacer>

                    <span v-if="debt.state===undefined && !isPrepaye"> État: - </span>
                    <span v-else-if="debt.state===0  && !isPrepaye">État: <b class="warning--text">En instance</b> </span>
                    <span v-else-if="debt.state===1  && !isPrepaye">État: <b class="success--text">Validé</b> </span>
                    <v-spacer></v-spacer>
                    <p
                      v-if="!Object.keys(debt).length"
                      class="d-flex flex-row"
                    >
                      <v-icon
                        color="error"
                        :small="true"
                        class="d-inline-block"
                      >
                        {{ icon.mdiAlertOutline }}
                      </v-icon>
                      <span
                        class="d-inline-block font-weight-semibold error--text pl-1 text-md"
                      > Facture pas encore disponible</span>
                    </p>
                    <v-btn
                      v-else
                      color="info"
                      outlined
                      x-small
                      class="mt-2"
                      @click="gotoBillsList"
                    >
                      Consulter la facture
                    </v-btn>
                    <v-btn
                      color="info"
                      outlined
                      x-small
                      class="mt-2"
                      @click="gotoPaymentList"
                    >
                      Consulter les paiements
                    </v-btn>
                  </v-card-text>
                </v-col>
                <v-col
                  v-if="!Object.keys(debt).length"
                  cols="6"
                  sm="12"
                  md="6"
                >
                  <v-card-text class="text-sm d-flex flex-column align-md-end">
                    <span><b>N° : </b><b class="info--text"> - </b></span>
                    <v-spacer></v-spacer>
                    <span>Montant : <b class="error--text">{{ sumField('amount').toLocaleString() }} {{ currency }}</b></span>
                    <v-spacer></v-spacer>
                    <span>Date de paiement : <b> - </b></span>
                  </v-card-text>
                </v-col>
                <v-col
                  v-else
                  cols="6"
                  sm="12"
                  md="6"
                >
                  <v-card-text class="text-sm d-flex flex-column align-md-end">
                    <span><b>N° : </b><b class="info--text">{{ debt.num_debt }}</b></span>
                    <v-spacer></v-spacer>
                    <span>Montant : <b class="error--text"> {{ withCurrency(parseInt(debt.amount)) }}</b></span>
                    <v-spacer></v-spacer>
                    <span v-if="debt.payment_date!==null  && !isPrepaye">Date paiement : <b> {{ debt.state===1 ? formatDate(payments[payments.length-1].created_at): formatDate(debt.payment_date) }}</b></span>
                  </v-card-text>
                </v-col>
              </v-row>
              <hr>
              <div v-if='!allPaymentList.length>=1'>
                <div v-if='debt.amount!==0'>
                  <v-row class="mt-2" v-if='!isPrepaye'>
                    <v-col
                      cols="6"
                      sm="12"
                      md="6"
                    >
                      <v-card-text>
                        <b>Paiement</b>
                        <v-spacer></v-spacer>
                        <span
                          v-if="debt.state===0"
                          class="mt-2"
                        >État: <b class="warning--text"> - </b></span>
                        <span
                          v-else-if="debt.state===1"
                          class="mt-2"
                        >État: <b class="text-success"> Validé </b></span>
                        <span
                          v-else
                          class="mt-2"
                        >État: - </span>
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if="debt.commitment_date==null &&  new Date(maxDate.MaxCommit).getTime() > new Date(nowDate).getTime()"
                          class="mt-2"
                          color="primary"
                          x-small
                          @click="dialog=true"
                        >
                          Engager le paiement
                        </v-btn>
                        <v-btn
                          v-else-if="(new Date(maxDate.MaxCommit).getTime()<new Date(nowDate).getTime()  && debt.commitment_date==null) && (debt.commitment_date!==null && debt.state!=1)"
                          class="mt-2 pl-11 pr-11"
                          color="primary"
                          x-small
                          @click="gotoDebtForm(debt.num_debt,debt.amount)"
                        >
                          Régler le paiement
                        </v-btn>
                        <v-btn
                          v-else-if='debt.state!=1'
                          class="mt-2 pl-11 pr-11"
                          color="primary"
                          x-small
                          @click="gotoDebtForm(debt.num_debt,debt.amount)"
                        >
                          Régler le paiement
                        </v-btn>
                        <!--                      <hr>
                                              <span>{{new Date(maxDate.MaxCommit).getTime()}}</span>
                                              <hr>
                                              <span>{{new Date(nowDate).getTime()}}</span>-->
                        <v-spacer></v-spacer>
                      </v-card-text>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="12"
                      md="6"
                    >
                      <v-card-text class="text-sm d-flex flex-column align-md-end">
                        <span><b>ID : </b><b>{{ debt.state===1 ? payments[payments.length -1 ].ref:'-' }}</b></span>
                        <span>Opérateur : <b>{{ debt.state===1 ? payMode:'-En instance' }}</b></span>
                        <v-spacer></v-spacer>
                        <span>Montant validé: <b class="success--text">{{ debt.state===1 ? withCurrency(debt.amount_paid): '- '+currency }}</b></span>
                        <v-spacer></v-spacer>
                        <span>Date de paiement : <b> {{ debt.state===1 ? formatDate(payments[payments.length-1].created_at): formatDate(debt.payment_date) }}</b></span>
                        <span>Date de validation : <b> {{ debt.state===1 ? formatDate(payments[payments.length-1].updated_at):'-En instance' }}</b></span>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-dialog
                v-model="dialog"
                persistent
                max-width="600px"
                outlined
              >
                <v-card>
                  <v-card-title>
                    <span class="text-h5 text-center">Délai de paiement</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          md="10"
                          offset="1"
                        >
                          <v-menu
                            v-model="commitment.menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="commitment.date"
                                label="Délai de paiement"
                                placeholder="Selectionner votre délai de paiement"
                                :readonly="true"
                                v-bind="attrs"
                                outlined
                                dense
                                autofocus
                                v-on="on"
                              >
                                <template v-slot:append>
                                  <v-icon>{{ icon.mdiCalendarMonth }}</v-icon>
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="commitment.date"
                              color="primary"
                              :allowed-dates="allowedDates"
                              locale="fr-fr"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      outlined
                      @click="closeDialog"
                    >
                      Annuler
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="loading"
                      @click="setPaymentDate"
                    >
                      Valider
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <v-dialog
      v-model="dialogDetail"
      persistent
    >
      <v-card>
        <v-card-title>
          <span> Détail <b></b></span>
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
          :headers="headersDetails"
          :items="detailItem"
          :options="detailsOptions"
          :items-per-page="-1"
          sort-by="id"
          class="table-kitchen-sink"
        >
          <!-- type local -->
          <!--              <template #[`item.type`]="{item}">-->
          <!--                <div class="d-flex align-center">-->
          <!--                  <div class="d-flex flex-column ms-3">-->
          <!--                    <span class="d-block font-weight-semibold text-truncate text&#45;&#45;primary">{{ item.customer_segment_id }}</span>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </template>-->

          <!-- nom local -->
          <template #[`item.num_ticket`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span
                  v-if="item.num_ticket"
                  class="d-block font-weight-semibold text-uppercase text-truncate primary--text font-weight-bold"
                >N° {{ item.num_ticket }}</span>
                <span
                  v-else
                  class="d-block font-weight-semibold text-uppercase text-truncate primary--text font-weight-bold"
                >N/A</span>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  class="align-content-end"
                >
                  <v-menu
                    offset-y
                    right
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        class="ma-2 custom-btn"
                        text
                        v-on="on"
                      >
                        <v-icon
                          class="error--text"
                        >
                          {{ icon.mdiHistory }}
                        </v-icon>
                        <v-icon>
                          {{ icon.mdiMenuDown }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-simple-table class="border-light">
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th
                            class="text-left"
                            colspan="2"
                          >
                            Transactions
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="(transaction, index) in item.service_requests_detail_transactions"
                          :key="index"
                        >
                          <td class="text-truncate bordure">
                            {{ formatDate(transaction.created_at) }}
                          </td>
                          <td class="bordure">
                            {{ transaction.message }}
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-menu>
                </v-col>
              </div>
            </div>
          </template>

          <!-- nombre decodeur -->
          <template #[`item.decodeur`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block font-weight-bold text-truncate primary--text">{{ item.decodeurInfo.location }} - {{ item.decodeurInfo.number }}</span>
              </div>
            </div>
          </template>

          <template #[`item.offre`]="{item}">
            <div
              v-if="item.operation === 1"
              class="d-flex align-center"
            >
              <div class="d-flex flex-column ms-3">
                <h4 class="d-block font-weight-semibold text-truncate primary--text">
                  Offre sollicité : <span class="success--text">{{ item.formulaInfo.label }}</span>
                </h4>
              </div>
            </div>
            <div
              v-else
              class="d-flex align-center"
            >
              <div class="d-flex flex-column ms-3 p-2">
                <h4 class="d-block font-weight-semibold text-truncate primary--text mt-2">
                  Offre actuel: <span class="font-weight-bold primary--text">{{ item.oldFormulaInfo.label }}</span>
                </h4>
                <h4 class="d-block font-weight-semibold text-truncate primary--text mb-2">
                  Option : <span class="font-weight-bold primary--text">{{ item.oldOptionInfo.nom }}</span>
                </h4>
                <v-divider></v-divider>

                <h4 class="d-block font-weight-semibold text-truncate primary--text mt-2">
                  Offre sollicité: <span class="success--text">{{ item.newFormulaInfo.label }}</span>
                </h4>
                <h4 class="d-block font-weight-semibold text-truncate primary--text mb-2">
                  Option : <span class="success--text">{{ item.newOptionInfo.nom }}</span>
                </h4>
              </div>
            </div>
          </template>

          <template #[`item.date`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block font-weight-bold text-truncate primary--text">
                  {{ item.activation_date }}
                </span>
              </div>
            </div>
          </template>

          <template #[`item.montant`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block font-weight-bold text-truncate primary--text">
                  {{ withCurrency(item.amount) }}
                </span>
              </div>
            </div>
          </template>

          <template #[`item.subsidised_amount`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block font-weight-bold text-truncate success--text">
                  {{ withCurrency(item.subsidised_amount) }}
                </span>
              </div>
            </div>
          </template>

          <template #[`item.subsidised_amount_paid`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block font-weight-bold text-truncate success--text">
                  {{ withCurrency(item.subsidised_amount_paid) }}
                </span>
              </div>
            </div>
          </template>

          <template #[`item.state`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <v-chip
                  v-if="item.state===1"
                  class="ma-2 text-center pr-5 pl-5"
                  color="#F1FFE8FF"
                >
                  <b class="success--text">Traité</b>
                </v-chip>
                <v-chip
                  v-else-if="item.state===0"
                  class="ma-2 text-center"
                  color="#FFF6ECFF"
                >
                  <b class="custom-warning--text">En cours</b>
                </v-chip>
                <v-chip
                  v-else
                  class="ma-2 text-center"
                  color="#FFECECFF"
                >
                  <b class="error--text">Rejeté</b>
                </v-chip>
              </div>
            </div>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="close"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>

import Http from '@/helpers/http'
import {
  computed, nextTick, onBeforeMount, ref,
} from '@vue/composition-api'
import moment from 'moment'
import Vue from 'vue'
import { mdiAlertOutline, mdiCalendarMonth, mdiEyeOutline,mdiHistory } from '@mdi/js'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { useRouter } from '@core/utils'
import useDynamicConstant from '@/helpers/useDynamicConstant'
import { isPostpayeContrat, PREPAYE_TYPE } from '@/helpers/constants/contrat'
import toast from '@/services/toast'
Vue.prototype.moment = moment

export default {
  setup() {
    const userData = ref([])
    const { CURRENCY } = useDynamicConstant()

    const currency = ref(CURRENCY)
    const storedData = JSON.parse(localStorage.getItem("clientFilleId"))
    const payments = ref([])
    const payMode = ref(' ')
    const billMonth = ref([])
    const sumAmount = ref(0)
    const dialog = ref(false)
    const onGoinPayment = ref(true)
    const dialogDetail = ref(false)
    const decodeurs = ref([])
    const allPaymentList = ref([])
    const commitment = ref({
      date: '',
      menu: false,
    })
    const loading = ref(false)
    const loadingDept = ref(false)
    const { route } = useRouter()
    const formatItemDetails = item => {
      // eslint-disable-next-line no-shadow
      console.log('item')
      console.log(item.Details)
      const result = {
        details: [],
      }

      item.Details.forEach(s => {
        // eslint-disable-next-line no-plusplus
        let element = {}
        if (parseInt(s.request_type_id) === 1) {
          // eslint-disable-next-line no-param-reassign,array-callback-return
          element.operation = parseInt(s.request_type_id)
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          element.decodeurInfo = decodeurs.value.filter(d => d.id === s.decoder_id)[0]
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          element.activation_date = moment(s.activation_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          console.log("element.decodeurInfo")
          console.log(s)
          console.log(element.decodeurInfo)
          console.log(formule)
          element.formulaInfo = formule.value.filter(f => f.code === element.decodeurInfo.formula)[0]
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          element.optionInfo = option.value.filter(o => o.code === element.decodeurInfo.option)[0]
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          element.amount = s.amount
          element.subsidised_amount = s.subsidised_amount
          element.subsidised_amount_paid = s.subsidised_amount_paid
          element.state = s.state
          element.loading = 0
          element.num_ticket=s.num_ticket
        } else {
          element.operation = parseInt(s.request_type_id)
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          element.decodeurInfo = element.value.filter(d => d.id === s.decoder_id)[0]
          // eslint-disable-next-line no-param-reassign,array-callback-return
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          element.activation_date = moment(s.activation_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          element.oldFormulaInfo = formule.value.filter(f => f.code === s.formula1)[0]
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          element.loading = 0
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          element.newFormulaInfo = formule.value.filter(f => f.code === s.formula2)[0]
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          element.oldOptionInfo = option.value.filter(o => o.code === s.option1)[0]
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          element.newOptionInfo = option.value.filter(o => o.code === s.option2)[0]
          element.amount = s.amount
          element.subsidised_amount = s.subsidised_amount
          element.subsidised_amount_paid = s.subsidised_amount_paid
          element.state = s.state
          element.num_ticket=s.num_ticket
        }

        console.log("element", element)
        result.details.push(element)
      })

      return result
    }

    const formule = ref([{}])
    const option = ref([{
      alpha2: 'CM',
      code: null,
      id: 0,
      nom: 'Aucune',
      pu: 0,
    }])

    const loadDecodeurs = () => {
      let storedData = JSON.parse(localStorage.getItem("clientFilleId"))
      let customerSegmentId = storedData.selectedLine.customer_segments[0].id

      Http.get(`get-decoders/${customerSegmentId}`)
        // eslint-disable-next-line no-return-assign
        .then(response => {
          decodeurs.value = response.data

          console.log('chargement des decodeurs terminés')
          console.log(decodeurs.value)
          // eslint-disable-next-line no-use-before-define
          loadEtatDette()
        })
        .catch(error => {
          console.log(error)
        })
    }
    const loadFormules = () => {
      Http.get('get-formules')
        .then(response => {
          formule.value = response.data
          console.log('chargement des formules terminées')
          console.log(formule.value)
          loadOptions()
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const loadOptions = () => {
      Http.get('get-options')
        .then(response => {
          option.value = [...option.value, ...response.data]

          console.log('chargement des options terminés')
          console.log(option.value)
          loadDecodeurs()
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }
    const loadEtatDette = () => {
      let storedData = JSON.parse(localStorage.getItem("clientFilleId"))
      let customerSegmentId = storedData.selectedLine.customer_segments[0].id
      Http.get(`customer-segment-debt/${customerSegmentId}`)
        .then(response => {
          if (response.status === 200) {
            console.log(response.data)

            console.log(response.data.paymentMonth)
            userData.value = Object.values(response.data.statementDebts)
            payments.value = response.data.paymentMonth
            billMonth.value = response.data.billsMonth
            console.log('billMonth.value')
            console.log(userData.value)
            console.log('payments.value')
            console.log(payments.value)
            console.log(payments.value[payments.value.length-1])

            loadDebtList()
            getDecodeur()
            getPaiementList()
            maxDateOfPayment()
            if(isPostpayeContrat()){
              loadPayment()
            }
          }
        })
        .catch(error => console.log(error))
    }

    const getPaiementList = () => {
      if(billId != undefined){
        let storedData = JSON.parse(localStorage.getItem("clientFilleId"))
        let customerSegmentId = storedData.selectedLine.customer_segments[0].id
        Http.get(`list-payment-debt/${customerSegmentId}/${billId}`)
          .then(response => {
            if (response.status === 200) {
              allPaymentList.value = response.data
              allPaymentList.value=response.data.filter(function(elt) {
                return elt.state == 0;
              });
            }
          })
      }else{
        loadingDept.value = false
      }

    }

    const getDecodeur = () =>{
      for(let i = 0; i < userData.value.length; i++){
        console.log('liste par jour', userData.value[i])
      }
    }

    const gotoDebtForm = (billNumber, billAmount) => {
      router.push({ name: 'remboursement-dette', params: { billNumber, billAmount,billId } })
    }

    const litteralMonth = monthNumber => moment().month((monthNumber - 1)).format('MMMM')

    const currentDate = moment().format('YYYY-MM-DD')

    const printDebtTable = () => {
      window.print()
    }

    const periode = JSON.parse(localStorage.getItem('periode'))
    const debtList = ref([])

    const debt = ref([])
    // eslint-disable-next-line radix
    const paramMonth = parseInt(moment(periode.Debut).format('M'))
    // eslint-disable-next-line radix
    const paramYear = parseInt(moment(periode.Debut).format('YYYY'))

    const loadDebtList = () => {
      loadingDept.value = true
      let storedData = JSON.parse(localStorage.getItem("clientFilleId"))
      let customerSegmentId = storedData.selectedLine.customer_segments[0].id
      let typeQuery = 1
      Http.get(
        `list-customer-segment-debt/${customerSegmentId}/${typeQuery} `,
      ).then(rep => {
        console.log('Réponse : ')
        console.log(rep.data)
        debtList.value = rep.data

        if (debtList.value.length !== 0) {
          debt.value = debtList.value.filter(item => {
            console.log((item.month === paramMonth && item.year === paramYear))

            return item.month === paramMonth && item.year === paramYear
          })[0]
        }
        loadingDept.value = false
        // debt.value = []
        console.log('debt')
        console.log(debt.value)
      }).catch(error => {
        console.error('Erreur :', error)
      })
    }
    const loadPayment = () => {
      Http.get(
        'get-paymode',
      ).then(rep => {
        console.log(rep.data)
        var local =rep.data.filter(function(number) {
          return number.code == payments.value[payments.value.length-1].payment_method_credit;
        })
        console.log('code')
        payMode.value= local[0].label
      }).catch(error => {
        console.error('Erreur :', error)
      })
    }

    const periodeDebt = computed(() => moment(periode.Debut).format('MMMM YYYY'))

    const maxDate = ref({})


    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    const nowDate = ref(Date(formattedDate))

    const maxDateOfPayment = () => {
      if (isPostpayeContrat()) {
        let customerSegmentId = storedData.selectedLine.customer_segments[0].id
        Http.get(`payment-date-period/${customerSegmentId}`)
          // eslint-disable-next-line consistent-return
          .then(response => {
            if (response.status === 200) {
              console.log(response.data)
              maxDate.value = response.data
              console.log("#########Max comit")
              console.log(maxDate.value.MaxCommit)
              console.log("######### now date")
              console.log(nowDate.value)
              console.log("#############max comit > now date ")
              console.log(maxDate.value.MaxCommit>nowDate.value)
              console.log("#############max comit < now date ")
              console.log(maxDate.value.MaxCommit<nowDate.value)
              console.log(maxDate.value)
              console.log(Date.now())
              if (moment(currentDate).diff(maxDate.value.fin, 'days') >= 0) {
                // eslint-disable-next-line no-multi-assign
                maxDate.value.debut = maxDate.value.fin = currentDate
              }
            }
          })
          .catch(error => {
            console.error(error)
            maxDate.value = { debut: moment().format('YYYY-MM-DD'), fin: moment().format('YYYY-MM-DD') }
          })
      }

    }
    const detailItem = ref([])

    const details = item => {
      console.log("my items")
      console.log(item)
      const detailsOperation = formatItemDetails(item)
      console.log('detailsOperation')
      console.log(detailsOperation)
      detailItem.value = detailsOperation.details
      dialogDetail.value = true
    }

    const gotoBillsList = () => {
      let param = debt.value
      let decList =  decodeurs.value
      router.push({ name: 'dette-facture', params: { param,decList } })
    }

    const close = () => {
      dialogDetail.value = false
      nextTick(() => {
        detailItem.value = []
      })
    }

    const gotoPaymentList = () => {
      if(isPrepaye){
        if(billId!=undefined){
          router.push({ name: 'list-recharge-treatedFille'})
        }else {
          toast.warning('Aucun paiement disponible ')
        }
      }else {
        if(billId!=undefined){
          router.push({ name: 'liste-paiementFille', params: {  billId } })
        }else {
          toast.warning('Aucun paiement disponible ')
        }
      }

    }

    const setPaymentDate = () => {
      loading.value = true
      const params = { paymentDate: commitment.value.date, commitmentDate: currentDate,billId:billId }
      let customerSegmentId = storedData.selectedLine.customer_segments[0].id
      Http.post(`set-payment-date/${customerSegmentId}`, params)
        .then(response => {
          console.log(response.data)
          if (response.status === 201) {
            console.info('engagement du paiement')
          }
          loadDebtList()
        })
        .catch(error => { console.error(error) })

      setTimeout(() => {
        // eslint-disable-next-line no-use-before-define
        closeDialog()
        loading.value = false
      }, 3000)
    }

    const withCurrency = price => `${Number(Math.ceil(price)).toLocaleString()} ${CURRENCY}`

    // eslint-disable-next-line no-return-assign
    const sumField = key =>

      // sum data in give key (property)
      // eslint-disable-next-line radix,implicit-arrow-linebreak
      userData.value.reduce((a, b) => Math.ceil(parseFloat(a)) + (Math.ceil(parseFloat(b[key])) || 0), 0)

    const formatDate = date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')

    const closeDialog = () => {
      dialog.value = false
      commitment.value.date = ''
    }

    const isCurrentTime = billMonth => (moment().month() + 1) !== billMonth

    const commitmentDate = () => {
      loading.value = true
      console.log('sending commitment')
      setTimeout(() => {
        closeDialog()
        loading.value = false
      }, 3000)
    }

    const isPrepaye = computed(() => PREPAYE_TYPE === storedData.selectedLine.customer_segments[0].customer_type_id)
    const billId = route.value.params.id

    onBeforeMount(() => {

      //console.log('route.value.params', route.value.params)
      loadFormules()
    })

    return {
      headers: [
        {
          text: 'DATE', value: 'date', divider: true, align: 'center',
        },
        {
          text: 'Nombre', value: 'activation', divider: true, align: 'center',
        },
        {
          text: 'Montant', value: 'amount', divider: true, align: 'center',
        },
        {
          text: 'Net à payer', value: 'amountDiscount', divider: true, align: 'center',
        },
        {
          text: 'Action', value: 'options', divider: true, align: 'center', sortable: false, class: 'hidden-print-only',
        },
      ],
      headersDetails: [
        { text: 'Ticket', value: 'num_ticket', divider: true },
        { text: 'Décodeur', value: 'decodeur', divider: true },
        { text: 'Offre', value: 'offre', divider: true },
        { text: 'Date d\'activation', value: 'date', divider: true },
        { text: 'Montant', value: 'montant', divider: true },
        { text: 'Net à payer', value: 'subsidised_amount', divider: true },
        { text: 'Montant payé', value: 'subsidised_amount_paid', divider: true },
        { text: 'Etat', value: 'state', divider: true },
      ],
      detailsOptions: {
        sortBy: ['operation'],
        sortDesc: [false],
      },
      userData,
      sumField,
      formatDate,
      currency,
      isCurrentTime,
      withCurrency,
      printDebtTable,

      payments,
      payMode,
      billMonth,
      sumAmount,
      dialog,
      dialogDetail,
      commitment,
      loading,
      onGoinPayment,
      loadingDept,
      commitmentDate,
      closeDialog,
      formatItemDetails,
      periodeDebt,
      details,
      decodeurs,
      loadDecodeurs,
      periode,
      debtList,
      allPaymentList,
      debt,
      loadDebtList,
      loadPayment,
      litteralMonth,
      paramMonth,
      paramYear,
      gotoDebtForm,
      setPaymentDate,
      detailItem,
      maxDateOfPayment,
      maxDate,
      nowDate,
      gotoPaymentList,
      gotoBillsList,
      close,
      isPrepaye,

      formule,
      option,
      loadFormules,
      loadOptions,
      icon: {
        mdiAlertOutline,
        mdiCalendarMonth,
        mdiEyeOutline,
        mdiHistory,
      },
    }

  },

  methods: {
    onBeforeMount() {
      console.log('route.value.params', this.$route.params)
      // alert(this.$route.params.id)
    },
    allowedDates(date) {

      console.log("max date")
      console.log(this.maxDate)
      console.log("max debutccc")
      console.log(this.maxDate.debut)
      console.log("max debutccc date")
      console.log(Date(this.maxDate.debut))
      var startDate = this.maxDate.debut // 1er janvier 2023
      if(new Date().getTime() > new Date(this.maxDate.debut).getTime()){
        startDate=new Date().toISOString().slice(0, 10);
      }
      const endDate = this.maxDate.fin // 31 janvier 2023
      return date >= startDate && date <= endDate;
    }
  },
}
</script>
<style  lang="scss">
th + th { border-left:1px solid rgb(191, 185, 199); }
td + td { border-left:1px solid rgb(191, 185, 199); }

.v_printed{
  visibility: hidden !important;
}
@media screen {
  .footer{
    display: none;
  }

}

@media print {

  a[href]:after {
    content: none !important;
  }

  @page {
    size: auto;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .page{
    margin-bottom: 100px !important;
  }

  .v-application {
    background: none !important;
  }

  .footer {
    position: fixed;
    bottom: 0;
    margin-top: 100px !important;
  }

  .title-printed{
    text-align: center;
  }

  .app-navigation-menu,
  .marquee-text,
  .v-app-bar,
  .v-footer,
  .billTab,
  .printBtn,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .v_printed{
    visibility: visible !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }
}
</style>
